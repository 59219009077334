<template>
  <div>
    <vs-popup :title="$t('CampaignType')" :active.sync="popupActive">
      <div
        class="flex flex-wrap"
        v-if="this.$store.state.acc.current_acc.smsDispatchEnable"
      >
        <div class="w-full p-2">
          <div class="alert-box mb-3 flex gap-x-5">
            <p>
              Atenção! Ao criar uma nova campanha, você concorda que pode haver
              custos extras na sua mensalidade Duotalk, de acordo com o volume
              de disparos e seu plano contratado.
            </p>
          </div>
        </div>

        <hr class="w-full border-line mb-5" />

        <div class="w-full flex flex-col gap-y-5">
          <h4 class="text-gray-light">
            Qual <u>tipo de campanha</u> que deseja criar?
          </h4>

          <div class="flex gap-x-5">
            <vs-radio
              v-model="campaignType"
              vs-name="campaignType"
              vs-value="whatsapp"
              class="custom-radio"
              >Whatsapp</vs-radio
            >
            <vs-radio
              v-model="campaignType"
              vs-name="campaignType"
              vs-value="sms"
              class="custom-radio"
              >SMS</vs-radio
            >
          </div>
          <label v-if="alertSms"
            >*Saldo insuficiente para envio via SMS, por favor entrar em contato
            com atendimento Duotalk.</label
          >
          <vs-button
            size="medium"
            class="block ml-auto"
            @click="handleCampaignTypeSubmit"
            >{{ $t("Accept") }}</vs-button
          >
        </div>
        <div class="w-full"></div>
      </div>

      <div
        class="flex flex-wrap"
        v-if="!this.$store.state.acc.current_acc.smsDispatchEnable"
      >
        <div class="w-full p-2">
          <p class="mb-3">
            Atenção! Ao criar uma campanha de WhatsApp, você concorda que pode
            haver custos extras na sua mensalidade Duotalk, de acordo com o
            volume de disparos e seu plano contratado.
          </p>
        </div>
        <div class="w-full">
          <vs-button
            size="medium"
            @click="$router.push('/apps/campaigns/new').catch(() => {})"
            >{{ $t("Accept") }}</vs-button
          >
        </div>
      </div>
    </vs-popup>
    <vs-popup
      :title="$t('History')"
      :active.sync="popupCampaignActive"
      fullscreen
    >
      <vs-row v-if="currentTab !== 2">
        <vs-col vs-type="flex" vs-align="center" vs-justify="space-between">
          <p class="text-xl">Disparos da campanha</p>
          <vs-tooltip :text="hasDownloadBlocked
              ? 'Função indisponível para esta conta'
              : 'Download XLSX'">
            <vs-button
              icon-pack="feather"
              icon="icon-file"
              @click="downloadCSV()"
              :disabled="hasDownloadBlocked"
            >
              {{ $t("DownloadXLSX") }}
            </vs-button>
          </vs-tooltip>
        </vs-col>
        <vs-col class="mt-5">
          <vs-table
            :max-items="campaingDetailsLimit"
            :data="campaignDetailsSending.campaigns"
            stripe
            :hoverFlat="true"
          >
            <template v-slot:thead>
              <vs-th></vs-th>
              <vs-th>{{ $t("Name") }}</vs-th>
              <vs-th>{{ $t("Phone") }}</vs-th>
              <vs-th>{{ $t("Status") }}</vs-th>
              <vs-th></vs-th>
              <vs-th></vs-th>
              <vs-th>{{ $t("Answer") }}</vs-th>
              <vs-th>{{ $t("Date") }}</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="indextr + 1">
                  {{ indextr + 1 }}
                </vs-td>
                <vs-td :data="tr.name">
                  {{ tr.name }}
                </vs-td>
                <vs-td :data="tr.phone">
                  {{ tr.phone }}
                </vs-td>
                <vs-td>
                  {{ getStatusString(tr.executeStatus, tr.executed) }}
                </vs-td>
                <vs-td :data="tr.executionSuccess">
                  <vs-chip color="success" v-if="tr.executionSuccess">
                    {{ $t("Success") }}
                  </vs-chip>
                  <vs-chip
                    color="danger"
                    v-else-if="tr.executionSuccess === false"
                  >
                    {{ $t("Error") }}
                  </vs-chip>
                </vs-td>
                <vs-td :data="tr.executionErrorMessage" class="truncate">
                  <span :title="tr.executionErrorMessage">
                    {{ tr.executionErrorMessage }}
                  </span>
                </vs-td>
                <vs-td :data="tr.answered">
                  <span>
                    {{
                      data[indextr].sendToOpenConversations && data[indextr].answerNotApplicable === true
                        ? "Não aplicável"
                        : tr.answered
                        ? $t("Yes")
                        : $t("No")
                    }}
                  </span>
                </vs-td>
                <vs-td :data="tr.executed">
                  <span v-if="tr.executed">
                    {{ new Date(tr.updatedAt).toLocaleDateString() }}
                    {{ $t("atTime") }}
                    {{ new Date(tr.updatedAt).toLocaleTimeString() }}
                  </span>
                  <span v-else>-</span>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-pagination
            class="mt-4"
            :total="campaignDetailsSending.pages"
            v-model="campaignDetailsPage"
          />
        </vs-col>
      </vs-row>
      <vs-row v-else>
        <vs-col vs-type="flex" vs-align="center" vs-justify="space-between">
          <p class="text-xl">Disparos da campanha</p>
          <vs-tooltip :text="hasDownloadBlocked
              ? 'Função indisponível para esta conta'
              : 'Download XLSX'">
            <vs-button
              icon-pack="feather"
              icon="icon-file"
              @click="downloadCSV()"
              :disabled="hasDownloadBlocked"
            >
              {{ $t("DownloadXLSX") }}
            </vs-button>
          </vs-tooltip>
        </vs-col>
        <vs-col class="mt-5">
          <vs-table
            :max-items="campaingDetailsLimit"
            :data="campaignDetailsSending.campaigns"
            stripe
            :hoverFlat="true"
          >
            <template v-slot:thead>
              <vs-th></vs-th>
              <vs-th>{{ $t("Name") }}</vs-th>
              <vs-th>{{ $t("Phone") }}</vs-th>
              <vs-th>{{ $t("Status") }}</vs-th>
              <vs-th></vs-th>
              <!-- <vs-th>{{ $t("Clicks") }}</vs-th> -->
              <vs-th>{{ $t("Date") }}</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="indextr + 1">
                  {{ indextr + 1 }}
                </vs-td>
                <vs-td :data="tr.name">
                  {{ tr.name }}
                </vs-td>
                <vs-td :data="tr.phone">
                  {{ tr.phone }}
                </vs-td>
                <vs-td :data="tr.executionSuccess">
                  <vs-chip
                    color="success"
                    v-if="tr.executionSuccess && tr.executeStatus > 2"
                  >
                    {{ $t("Success") }}
                  </vs-chip>
                  <vs-chip
                    color="primary"
                    v-else-if="tr.executionSuccess && tr.executeStatus < 3"
                  >
                    {{ $t("Waiting") }}
                  </vs-chip>
                  <vs-chip
                    color="danger"
                    v-else-if="tr.executionSuccess === false"
                  >
                    {{ $t("Error") }}
                  </vs-chip>
                </vs-td>
                <vs-td :data="tr.executionErrorMessage" class="truncate">
                  <span :title="tr.executionErrorMessage">
                    {{ tr.executionErrorMessage }}
                  </span>
                </vs-td>
                <!-- <vs-td :data="tr.answered">
                  <span>
                    {{ tr.answered ? $t("Yes") : $t("No") }}
                  </span>
                </vs-td> -->
                <vs-td :data="tr.executed">
                  <span v-if="tr.executed">
                    {{ new Date(tr.updatedAt).toLocaleDateString() }}
                    {{ $t("atTime") }}
                    {{ new Date(tr.updatedAt).toLocaleTimeString() }}
                  </span>
                  <span v-else>-</span>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <vs-pagination
            class="mt-4"
            :total="campaignDetailsSending.pages"
            v-model="campaignDetailsPage"
          />
        </vs-col>
      </vs-row>
    </vs-popup>
    <h3 class="mb-5 mt-10">
      {{ $t("Campaigns") }}
    </h3>
    <vs-tabs position="left" v-model="currentTab">
      <vs-tab
        label="Whatsapp"
        icon-pack="feather"
        icon="icon-send"
        class="pt-0"
      >
        <vs-card class="p-3 shadow-none">
          <vs-row>
            <vs-col>
              <vs-button
                class="mr-5"
                @click="popupActive = true"
                icon-pack="feather"
                icon="icon-plus"
                icon-after
              >
                {{ $t("NewCampaign") }}
              </vs-button>
            </vs-col>
            <vs-col class="mt-5">
              <vs-table
                :max-items="limit"
                stripe
                :data="manualSending.campaigns"
              >
                <template slot="thead">
                  <vs-th>{{ $t("CampaignName") }}</vs-th>
                  <vs-th>{{ $t("CampaignDescription") }}</vs-th>
                  <vs-th>{{ $t("User") }}</vs-th>
                  <vs-th>{{ $t("Date") }}</vs-th>
                  <vs-th>{{ $t("Status") }}</vs-th>
                  <vs-th></vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].name">
                      <div class="w-full">
                        {{ data[indextr].name }}
                      </div>
                      <div
                        class="w-full text-xs"
                        v-if="data[indextr].type !== 'sms'"
                      >
                        <span v-if="data[indextr].templateName">
                          {{ $t("Template") }}:
                          {{ data[indextr].templateName }} <br />
                        </span>
                        <span v-if="data[indextr].intention">
                          {{ $t("Intention") }}: {{ data[indextr].intention }}
                          <br />
                        </span>
                        <span v-if="data[indextr].entity">
                          {{ $t("EntityKey") }}: {{ data[indextr].entity }}
                          <br />
                        </span>
                        <span>
                          {{ $t("OpenedConversations") }}:
                          {{
                            data[indextr].sendToOpenConversations
                              ? $t("Yes")
                              : $t("No")
                          }}
                          <br />
                        </span>
                      </div>
                    </vs-td>
                    <vs-td :data="data[indextr].description">
                      {{ data[indextr].description }}
                    </vs-td>
                    <vs-td :data="data[indextr].userName" class="truncate">
                      {{ data[indextr].userName }}
                    </vs-td>
                    <vs-td :data="data[indextr].createdAt">
                      <template v-if="!data[indextr].scheduleDate">
                        {{
                          new Date(data[indextr].createdAt).toLocaleDateString()
                        }}
                        {{ $t("atTime") }}
                        {{
                          new Date(data[indextr].createdAt).toLocaleTimeString()
                        }}
                      </template>
                      <template v-else-if="data[indextr].status < 3">
                        <div
                          v-if="
                            new Date() <= new Date(data[indextr].scheduleDate)
                          "
                          class="flex items-center text-primary"
                        >
                          <feather-icon
                            svgClasses="h-4 w-4 mr-2"
                            icon="ClockIcon"
                          />
                          Agendado para<br />
                          {{
                            new Date(
                              data[indextr].scheduleDate
                            ).toLocaleDateString()
                          }}
                          {{ $t("atTime") }}
                          {{
                            new Date(
                              data[indextr].scheduleDate
                            ).toLocaleTimeString()
                          }}
                        </div>
                        <div v-else class="flex items-center">
                          {{
                            new Date(
                              data[indextr].scheduleDate
                            ).toLocaleDateString()
                          }}
                          {{ $t("atTime") }}
                          {{
                            new Date(
                              data[indextr].scheduleDate
                            ).toLocaleTimeString()
                          }}
                        </div>
                      </template>
                      <template v-else-if="data[indextr].status === 3">
                        {{
                          new Date(
                            data[indextr].scheduleDate
                          ).toLocaleDateString()
                        }}
                        {{ $t("atTime") }}
                        {{
                          new Date(
                            data[indextr].scheduleDate
                          ).toLocaleTimeString()
                        }}
                      </template>
                      <template v-else-if="data[indextr].status > 3">
                        <div class="flex items-center text-danger">
                          <feather-icon
                            svgClasses="h-4 w-4 mr-2"
                            icon="XCircleIcon"
                          />
                          Envio cancelado
                        </div>
                      </template>
                    </vs-td>
                    <vs-td :data="data[indextr].totalContacts">
                      <div class="flex flex-wrap gap-x-1">
                        <vs-chip color="primary">
                          {{ $t("Total") }}: {{ data[indextr].totalContacts }}
                        </vs-chip>
                        <vs-chip color="success">
                          {{ $t("Success") }}:
                          {{ data[indextr].totalSuccess || 0 }}
                          {{
                            getTypePercent(
                              data[indextr].totalContacts,
                              data[indextr].totalSuccess
                            )
                          }}
                        </vs-chip>
                        <vs-chip color="grey">
                          {{ $t("Error") }}:
                          {{ data[indextr].totalErrors || 0 }}
                          {{
                            getTypePercent(
                              data[indextr].totalContacts,
                              data[indextr].totalErrors
                            )
                          }}
                        </vs-chip>
                        <vs-chip color="warning">
                          {{ $t("Answers") }}:
                          {{ data[indextr].totalAnswers || 0 }}
                          {{
                            getTypePercent(
                              data[indextr].totalContacts,
                              data[indextr].totalAnswers
                            )
                          }}
                        </vs-chip>
                      </div>
                    </vs-td>
                    <vs-td>
                      <div
                        class="flex"
                        :class="
                          data[indextr].status < 3 &&
                          data[indextr].scheduleDate !== null
                            ? 'justify-around'
                            : 'justify-center'
                        "
                      >
                        <vs-button
                          color="primary"
                          type="border"
                          icon-pack="feather"
                          icon="icon-search"
                          radius
                          @click="getCampaignInfo(data[indextr]._id)"
                        />
                        <vs-tooltip text="Cancelar envio" color="danger">
                          <vs-button
                            color="danger"
                            type="border"
                            icon-pack="feather"
                            icon="icon-x"
                            radius
                            v-if="
                              data[indextr].status < 3 &&
                              data[indextr].scheduleDate !== null
                            "
                            @click="openPopupCancelSchedule(data[indextr])"
                          />
                        </vs-tooltip>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <vs-pagination
                class="mt-4"
                :total="manualSending.pages"
                v-model="manualSendingPage"
              />
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-tab>

      <vs-tab
        label="Automações"
        icon-pack="feather"
        icon="icon-link"
        class="pt-0"
      >
        <vs-card class="p-3 shadow-none">
          <vs-row>
            <vs-col>
              <vs-button
                class="mr-5"
                @click="popupActive = true"
                icon-pack="feather"
                icon="icon-plus"
                icon-after
              >
                {{ $t("NewCampaign") }}
              </vs-button>
            </vs-col>
            <vs-col class="mt-5">
              <vs-table
                :max-items="limit"
                stripe
                :data="automationSending.campaigns"
              >
                <template slot="thead">
                  <vs-th>{{ $t("CampaignName") }}</vs-th>
                  <vs-th>{{ $t("CampaignDescription") }}</vs-th>
                  <vs-th>{{ $t("User") }}</vs-th>
                  <vs-th>{{ $t("Date") }}</vs-th>
                  <vs-th>{{ $t("Status") }}</vs-th>
                  <vs-th></vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].name">
                      <div class="w-full">
                        {{ data[indextr].name }}
                      </div>
                      <div class="w-full text-xs">
                        <span v-if="data[indextr].templateName">
                          {{ $t("Template") }}:
                          {{ data[indextr].templateName }} <br />
                        </span>
                        <span v-if="data[indextr].intention">
                          {{ $t("Intention") }}: {{ data[indextr].intention }}
                          <br />
                        </span>
                        <span v-if="data[indextr].entity">
                          {{ $t("EntityKey") }}: {{ data[indextr].entity }}
                          <br />
                        </span>
                        <span>
                          {{ $t("OpenedConversations") }}:
                          {{
                            data[indextr].sendToOpenConversations
                              ? $t("Yes")
                              : $t("No")
                          }}
                          <br />
                        </span>
                      </div>
                    </vs-td>
                    <vs-td :data="data[indextr].description">
                      {{ data[indextr].description }}
                    </vs-td>

                    <vs-td :data="data[indextr].userName">
                      {{ data[indextr].userName }}
                    </vs-td>
                    <vs-td :data="data[indextr].createdAt">
                      <template v-if="!data[indextr].scheduleDate">
                        {{
                          new Date(data[indextr].createdAt).toLocaleDateString()
                        }}
                        {{ $t("atTime") }}
                        {{
                          new Date(data[indextr].createdAt).toLocaleTimeString()
                        }}
                      </template>
                      <template v-else-if="data[indextr].status < 3">
                        <div class="flex items-center text-primary">
                          <feather-icon
                            svgClasses="h-4 w-4 mr-2"
                            icon="ClockIcon"
                          />
                          Agendado para<br />
                          {{
                            new Date(
                              data[indextr].scheduleDate
                            ).toLocaleDateString()
                          }}
                          {{ $t("atTime") }}
                          {{
                            new Date(
                              data[indextr].scheduleDate
                            ).toLocaleTimeString()
                          }}
                        </div>
                      </template>
                      <template v-else-if="data[indextr].status === 3">
                        {{
                          new Date(
                            data[indextr].scheduleDate
                          ).toLocaleDateString()
                        }}
                        {{ $t("atTime") }}
                        {{
                          new Date(
                            data[indextr].scheduleDate
                          ).toLocaleTimeString()
                        }}
                      </template>
                      <template v-else-if="data[indextr].status > 3">
                        <div class="flex items-center text-danger">
                          <feather-icon
                            svgClasses="h-4 w-4 mr-2"
                            icon="XCircleIcon"
                          />
                          Envio cancelado
                        </div>
                      </template>
                    </vs-td>
                    <vs-td :data="data[indextr].totalContacts">
                      <div class="flex gap-x-3">
                        <vs-chip color="primary">
                          {{ $t("Total") }}: {{ data[indextr].totalContacts }}
                        </vs-chip>
                        <vs-chip color="success">
                          {{ $t("Success") }}:
                          {{ data[indextr].totalSuccess || 0 }}
                          {{
                            getTypePercent(
                              data[indextr].totalContacts,
                              data[indextr].totalSuccess
                            )
                          }}
                        </vs-chip>
                        <vs-chip color="grey">
                          {{ $t("Error") }}:
                          {{ data[indextr].totalErrors || 0 }}
                          {{
                            getTypePercent(
                              data[indextr].totalContacts,
                              data[indextr].totalErrors
                            )
                          }}
                        </vs-chip>
                        <vs-chip color="warning">
                          {{ $t("Answers") }}:
                          {{ data[indextr].totalAnswers || 0 }}
                          {{
                            getTypePercent(
                              data[indextr].totalContacts,
                              data[indextr].totalAnswers
                            )
                          }}
                        </vs-chip>
                      </div>
                    </vs-td>
                    <vs-td>
                      <div
                        class="flex"
                        :class="
                          data[indextr].status < 3 &&
                          data[indextr].scheduleDate !== null
                            ? 'justify-around'
                            : 'justify-center'
                        "
                      >
                        <vs-button
                          color="primary"
                          type="border"
                          icon-pack="feather"
                          icon="icon-search"
                          radius
                          @click="getCampaignInfo(data[indextr]._id)"
                        />
                        <vs-tooltip text="Cancelar envio" color="danger">
                          <vs-button
                            color="danger"
                            type="border"
                            icon-pack="feather"
                            icon="icon-x"
                            radius
                            v-if="
                              data[indextr].status < 3 &&
                              data[indextr].scheduleDate !== null &&
                              new Date() <= new Date(data[indextr].scheduleDate)
                            "
                            @click="openPopupCancelSchedule(data[indextr])"
                          />
                        </vs-tooltip>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <vs-pagination
                class="mt-4"
                :total="automationSending.pages"
                v-model="automationSendingPage"
              />
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-tab>
      <vs-tab
        label="SMS"
        icon-pack="feather"
        icon="icon-send"
        class="pt-0"
        v-if="enableSMS"
      >
        <vs-card class="p-3 shadow-none">
          <vs-row>
            <vs-col>
              <vs-button
                class="mr-5"
                @click="popupActive = true"
                icon-pack="feather"
                icon="icon-plus"
                icon-after
              >
                {{ $t("NewCampaign") }}
              </vs-button>
            </vs-col>
            <vs-col class="mt-5">
              <vs-table :max-items="limit" :data="smsSending.campaigns">
                <template slot="thead">
                  <vs-th>{{ $t("CampaignName") }}</vs-th>
                  <vs-th>{{ $t("CampaignDescription") }}</vs-th>
                  <vs-th>{{ $t("User") }}</vs-th>
                  <vs-th>{{ $t("Date") }}</vs-th>
                  <vs-th>{{ $t("Status") }}</vs-th>
                  <vs-th></vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].name">
                      <div class="w-full">
                        {{ data[indextr].name }}
                      </div>
                      <div
                        class="w-full text-xs"
                        v-if="data[indextr].type !== 'sms'"
                      >
                        <span v-if="data[indextr].templateName">
                          {{ $t("Template") }}:
                          {{ data[indextr].templateName }} <br />
                        </span>
                        <span v-if="data[indextr].intention">
                          {{ $t("Intention") }}: {{ data[indextr].intention }}
                          <br />
                        </span>
                        <span v-if="data[indextr].entity">
                          {{ $t("EntityKey") }}: {{ data[indextr].entity }}
                          <br />
                        </span>
                        <span>
                          {{ $t("OpenedConversations") }}:
                          {{
                            data[indextr].sendToOpenConversations
                              ? $t("Yes")
                              : $t("No")
                          }}
                          <br />
                        </span>
                      </div>
                    </vs-td>
                    <vs-td :data="data[indextr].description">
                      {{ data[indextr].description }}
                    </vs-td>
                    <vs-td :data="data[indextr].userName" class="truncate">
                      {{ data[indextr].userName }}
                    </vs-td>
                    <vs-td :data="data[indextr].createdAt">
                      <template v-if="!data[indextr].scheduleDate">
                        {{
                          new Date(data[indextr].createdAt).toLocaleDateString()
                        }}
                        {{ $t("atTime") }}
                        {{
                          new Date(data[indextr].createdAt).toLocaleTimeString()
                        }}
                      </template>
                      <template v-else-if="data[indextr].status < 3">
                        <div
                          v-if="
                            new Date() <= new Date(data[indextr].scheduleDate)
                          "
                          class="flex items-center text-primary"
                        >
                          <feather-icon
                            svgClasses="h-4 w-4 mr-2"
                            icon="ClockIcon"
                          />
                          Agendado para<br />
                          {{
                            new Date(
                              data[indextr].scheduleDate
                            ).toLocaleDateString()
                          }}
                          {{ $t("atTime") }}
                          {{
                            new Date(
                              data[indextr].scheduleDate
                            ).toLocaleTimeString()
                          }}
                        </div>
                        <div v-else class="flex items-center">
                          {{
                            new Date(
                              data[indextr].scheduleDate
                            ).toLocaleDateString()
                          }}
                          {{ $t("atTime") }}
                          {{
                            new Date(
                              data[indextr].scheduleDate
                            ).toLocaleTimeString()
                          }}
                        </div>
                      </template>
                      <template v-else-if="data[indextr].status === 3">
                        {{
                          new Date(
                            data[indextr].scheduleDate
                          ).toLocaleDateString()
                        }}
                        {{ $t("atTime") }}
                        {{
                          new Date(
                            data[indextr].scheduleDate
                          ).toLocaleTimeString()
                        }}
                      </template>
                      <template v-else-if="data[indextr].status > 3">
                        <div class="flex items-center text-danger">
                          <feather-icon
                            svgClasses="h-4 w-4 mr-2"
                            icon="XCircleIcon"
                          />
                          Envio cancelado
                        </div>
                      </template>
                    </vs-td>
                    <vs-td :data="data[indextr].totalContacts">
                      <div class="flex flex-wrap gap-x-1">
                        <vs-chip color="primary">
                          {{ $t("Total") }}: {{ data[indextr].totalContacts }}
                        </vs-chip>
                        <vs-chip color="success">
                          {{ $t("Success") }}:
                          {{ data[indextr].totalSuccess || 0 }}
                          {{
                            getTypePercent(
                              data[indextr].totalContacts,
                              data[indextr].totalSuccess
                            )
                          }}
                        </vs-chip>
                        <vs-chip color="grey">
                          {{ $t("Error") }}:
                          {{ data[indextr].totalErrors || 0 }}
                          {{
                            getTypePercent(
                              data[indextr].totalContacts,
                              data[indextr].totalErrors
                            )
                          }}
                        </vs-chip>
                        <!-- <vs-chip color="primary">
                              {{ $t("Clicks") }}:
                              {{ data[indextr].totalClicks || 0 }}
                              {{
                                getTypePercent(
                                  data[indextr].totalContacts,
                                  data[indextr].totalClicks
                                )
                              }}
                            </vs-chip> -->
                      </div>
                    </vs-td>
                    <vs-td>
                      <div
                        class="flex"
                        :class="
                          data[indextr].status < 3 &&
                          data[indextr].scheduleDate !== null
                            ? 'justify-around'
                            : 'justify-center'
                        "
                      >
                        <vs-button
                          color="primary"
                          type="border"
                          icon-pack="feather"
                          icon="icon-search"
                          radius
                          @click="getCampaignInfo(data[indextr]._id)"
                        />
                        <vs-tooltip text="Cancelar envio" color="danger">
                          <vs-button
                            color="danger"
                            type="border"
                            icon-pack="feather"
                            icon="icon-x"
                            radius
                            v-if="
                              data[indextr].status < 3 &&
                              data[indextr].scheduleDate !== null
                            "
                            @click="openPopupCancelSchedule(data[indextr])"
                          />
                        </vs-tooltip>
                      </div>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <vs-pagination
                class="mt-4"
                :total="smsSending.pages"
                v-model="smsSendingPage"
              />
            </vs-col>
          </vs-row>
        </vs-card>
      </vs-tab>
    </vs-tabs>

    <vs-popup title="Cancelar agendamento" :active.sync="popupCancelSchedule">
      <vs-row>
        <vs-col>
          <p class="text-base">
            Ao optar pelo cancelamento, a campanha
            <b>{{
              campaignCancelSchedule ? campaignCancelSchedule.name : ""
            }}</b
            >,<br />
            agendada para
            <b>
              {{
                campaignCancelSchedule
                  ? new Date(
                      campaignCancelSchedule.scheduleDate
                    ).toLocaleDateString()
                  : ""
              }}
            </b>
            {{ $t("atTime") }}
            <b>
              {{
                campaignCancelSchedule
                  ? new Date(
                      campaignCancelSchedule.scheduleDate
                    ).toLocaleTimeString()
                  : ""
              }}
            </b>
            não será mais enviada.<br /><br />
            Confirmar cancelamento?
          </p>
        </vs-col>
        <vs-col class="mt-5">
          <vs-button
            color="danger"
            @click="handleCancelSchedule(campaignCancelSchedule._id)"
          >
            Cancelar agendamento
          </vs-button>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import xlsx from "xlsx/dist/xlsx.full.min";

export default {
  data() {
    return {
      limit: 30,
      currentTab: 0,
      smsSendingPage: 1,
      manualSendingPage: 1,
      campaignDetailsPage: 1,
      automationSendingPage: 1,
      campaingDetailsLimit: 200,
      smsSending: {
        total: 0,
        campaigns: [],
        pages: 0,
      },
      manualSending: {
        total: 0,
        campaigns: [],
        pages: 0,
      },
      automationSending: {
        total: 0,
        campaigns: [],
        pages: 0,
      },
      campaignDetailsSending: {
        total: 0,
        campaigns: [],
        pages: 0,
      },
      campaignDetails: [],
      alertSms: false,
      popupActive: false,
      popupCampaignActive: false,
      popupCancelSchedule: false,
      lastUpdate: null,
      campaignType: null,
      selectedCampaignId: null,
      campaignCancelSchedule: null,
      enableSMS: this.$store.state.acc.current_acc.smsDispatchEnable,
    };
  },
  async mounted() {
    await this.manualList();
    // await this.smsList();
    // await this.automationList();
  },
  methods: {
    openPopupCancelSchedule(campaign) {
      this.popupCancelSchedule = true;
      this.campaignCancelSchedule = campaign;
    },
    getPercent(value, total) {
      if (total === 0) return "0%";
      return `${(((value || 0) / (total || 0)) * 100).toFixed(2)}%`;
    },
    getTypePercent(total, elements) {
      if (total < 0 || total == 0 || !elements || !total) {
        return "(0%)";
      }

      let percent = elements / total;
      return (
        "(" +
        percent.toLocaleString(undefined, {
          style: "percent",
          minimumFractionDigits: 0,
        }) +
        ")"
      );
    },
    getTypeString(val) {
      if (val === "whatsapp") return "Whatsapp";
      if (val === "sms") return "SMS";
      return val;
    },
    getStatusString(val, executed) {
      if (executed) return this.$t("Finished");
      if (val === 1) return this.$t("Waiting");
      if (val === 2) return this.$t("Executing");
      if (val === 3) return this.$t("Paused");
      if (val === 4) return this.$t("Stopped");
      if (val === 5) return this.$t("Finished");
      return this.$t("Unknown");
    },
    getCampaignInfo(id, page) {
      this.selectedCampaignId = id;
      this.$vs.loading();
      this.campaignDetails = [];
      this.$http
        .get(
          `/p/campaign/details/${id}?acc=${this.$store.state.acc.current_acc.id}`,
          {
            params: {
              page: page || 1,
              limit: this.campaingDetailsLimit,
            },
          }
        )
        .then(async (result) => {
          this.$vs.loading.close();
          const data = result.data.data;
          this.campaignDetails = data.campaigns;
          this.campaignDetailsSending = data;
          this.campaignDetailsPage = data.page;
          this.popupCampaignActive = true;
        })
        .catch(() => {
          this.$vs.loading.close();
          this.$vs.notify({
            time: 2500,
            title: this.$t("Error"),
            text: this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-success-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    handleCampaignTypeSubmit() {
      this.alertSms = false;
      if (!this.campaignType) return;

      switch (this.campaignType) {
        case "whatsapp":
          this.$router.push("/apps/campaigns/new").catch(() => {});
          break;
        case "sms":
          if (parseInt(this.$store.state.acc.current_acc.smsCredit) > 0) {
            this.$router.push("/apps/campaigns/sms/new").catch(() => {});
          } else {
            this.alertSms = true;
          }
          break;

        default:
          break;
      }
    },
    async handleCancelSchedule(id) {
      const response = await this.$http.delete("/p/campaign/" + id, {
        params: {
          acc: this.$store.state.acc.current_acc.id,
        },
      });

      if (response.status === 200) {
        this.popupCancelSchedule = false;
        this.campaignCancelSchedule = false;

        this.automationSending.campaigns.forEach((el, i) => {
          if (el._id === id) this.automationSending.campaigns[i].status = 5;
        });

        this.manualSending.campaigns.forEach((el, i) => {
          if (el._id === id) this.manualSending.campaigns[i].status = 5;
        });

        this.$vs.notify({
          time: 2500,
          title: this.$t("Success"),
          text: "Agendamento cancelado com sucesso.",
          iconPack: "feather",
          icon: "icon-check",
          color: "success",
          position: "top-right",
        });
      } else {
        this.$vs.notify({
          time: 2500,
          title: this.$t("Error"),
          text: "Erro ao cancelar agendamento.",
          iconPack: "feather",
          icon: "icon-x",
          color: "danger",
          position: "top-right",
        });
      }
    },
    async fetchAllCampaignDetails(id) {
      let allCampaignDetails = [];
      let currentPage = 1;
      let totalPages = 1;

      while (currentPage <= totalPages) {
        await this.$http
          .get(
            `/p/campaign/details/${id}?acc=${this.$store.state.acc.current_acc.id}`,
            {
              params: {
                page: currentPage,
                limit: this.campaingDetailsLimit,
              },
            }
          )
          .then((result) => {
            const data = result.data.data;
            allCampaignDetails = allCampaignDetails.concat(data.campaigns);
            totalPages = data.pages;
            currentPage++;
          })
          .catch(() => {
            this.$vs.loading.close();
            this.$vs.notify({
              time: 2500,
              title: this.$t("Error"),
              text: this.$t("UnexpectedError"),
              iconPack: "feather",
              icon: "icon-success-circle",
              color: "danger",
              position: "top-right",
            });
          });
      }

      return allCampaignDetails;
    },
    formatLastUpdate(endDate) {
      let day = endDate.getDate().toString().padStart(2, "0");
      let month = (endDate.getMonth() + 1).toString().padStart(2, "0");
      let year = endDate.getFullYear();

      let hours = endDate.getHours().toString().padStart(2, "0");
      let minutes = endDate.getMinutes().toString().padStart(2, "0");
      let seconds = endDate.getSeconds().toString().padStart(2, "0");
      const formattedEndDate = `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;

      return formattedEndDate;
    },
    async manualList(page) {
      this.$vs.loading();

      await this.$http
        .get(`/p/campaign/list`, {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            page: page || 1,
            limit: this.limit,
            userName: "users",
            type: "whatsapp",
          },
        })
        .then(async (result) => {
          const endDate = new Date();
          this.manualSending = result.data.data;
          this.manualSendingPage = result.data.data.page;
          this.$vs.loading.close();
          this.lastUpdate = this.formatLastUpdate(endDate);
        })
        .catch(() => {
          this.$vs.notify({
            time: 2500,
            title: this.$t("Error"),
            text: this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-x",
            color: "danger",
            position: "top-right",
          });
        });
    },
    async smsList(page) {
      this.$vs.loading();
      await this.$http
        .get(`/p/campaign/list`, {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            page: page || 1,
            limit: this.limit,
            userName: "users",
            type: "sms",
          },
        })
        .then(async (result) => {
          const endDate = new Date();
          this.$vs.loading.close();
          this.smsSending = result.data.data;
          this.smsSendingPage = result.data.data.page;
          this.lastUpdate = this.formatLastUpdate(endDate);
        })
        .catch(() => {
          this.$vs.notify({
            time: 2500,
            title: this.$t("Error"),
            text: this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-x",
            color: "danger",
            position: "top-right",
          });
        });
    },
    async automationList(page) {
      this.$vs.loading();
      await this.$http
        .get(`/p/campaign/list`, {
          params: {
            acc: this.$store.state.acc.current_acc.id,
            page: page || 1,
            limit: this.limit,
            userName: "system",
          },
        })
        .then(async (result) => {
          const endDate = new Date();
          this.$vs.loading.close();
          this.automationSending = result.data.data;
          this.automationSendingPage = result.data.data.page;
          this.lastUpdate = this.formatLastUpdate(endDate);
        })
        .catch(() => {
          this.$vs.notify({
            time: 2500,
            title: this.$t("Error"),
            text: this.$t("UnexpectedError"),
            iconPack: "feather",
            icon: "icon-x",
            color: "danger",
            position: "top-right",
          });
        });
    },
    async downloadCSV() {
      this.$vs.loading();

      const allCampaignDetails = await this.fetchAllCampaignDetails(
        this.selectedCampaignId
      );

      // June track
      window.analytics.track(
        "Download Campanhas",
        {},
        { groupId: this.$store.state.acc.current_acc.id }
      );

      const XLSX = xlsx,
        workbook = XLSX.utils.book_new(),
        arrayXlsx = [];

      const headers = {
        conversation: "ID da conversa",
        name: "Nome",
        phone: "Telefone",
        executionSuccess: "Status da execução",
        executionErrorMessage: "Mensagem do status de execução",
        answered: "Resposta",
        executed: "Status",
        updatedAt: "Data",
      };

      allCampaignDetails.forEach((object) => {
        const data = {
          [headers.conversation]: object.conversation,
          [headers.name]: object.name,
          [headers.phone]: object.phone,
          [headers.executionSuccess]:
            object.executionSuccess === true
              ? "Sim"
              : object.executionSuccess === false
              ? "Não"
              : object.executionSuccess,
          [headers.executionErrorMessage]: object.executionErrorMessage || "",
          [headers.executed]: this.getStatusString(
            object.executeStatus,
            object.executed
          ),
          [headers.updatedAt]: `${new Date(
            object.updatedAt
          ).toLocaleDateString()} ${this.$t("atTime")} ${new Date(
            object.updatedAt
          ).toLocaleTimeString()}`,
          [headers.answered]:
            object.sendToOpenConversations && object.answerNotApplicable === true 
              ? "Não aplicável"
              : object.answered === true
              ? "Sim"
              : object.answered === false
              ? "Não"
              : object.answered,
        };
        arrayXlsx.push(data);
      });

      const worksheet = XLSX.utils.json_to_sheet(arrayXlsx);
      XLSX.utils.book_append_sheet(workbook, worksheet, "report");
      XLSX.writeFile(workbook, "campaigns-history.xlsx");
      setTimeout(() => {
        this.$vs.loading.close();
      }, 500);
    },
  },
  watch: {
    async automationSendingPage() {
      await this.automationList(this.automationSendingPage);
    },
    async manualSendingPage() {
      await this.manualList(this.manualSendingPage);
    },
    async smsSendingPage() {
      await this.smsList(this.smsSendingPage);
    },
    async campaignDetailsPage() {
      this.getCampaignInfo(this.selectedCampaignId, this.campaignDetailsPage);
    },
    async smsDispatchEnable() {
      this.enableSMS = this.$store.state.acc.current_acc.smsDispatchEnable;
    },
    currentTab(val) {
      switch (val) {
        case 0:
          this.manualList();
          break;
        case 1:
          this.automationList();
          break;
        case 2:
          this.smsList();
          break;
      }
    },
  },
  components: {},
  computed: {
    smsDispatchEnable() {
      return this.$store.state.acc.current_acc.smsDispatchEnable;
    },
    hasDownloadBlocked() {
      return this.$store.state.acc.current_acc.blockDownloadExcel;
    },
  },
};
</script>
<style scoped lang="scss">
.custom-radio {
  width: 100%;
  padding: 1.25rem;
  border: 1px solid;
  border-radius: 6px;

  font-weight: bold;
  cursor: pointer;

  &:has(input:checked) {
    border-color: rgb(115, 103, 240);

    &::v-deep .vs-radio--circle {
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 44%;
        height: 44%;
        background-color: #fff;
        border-radius: 100vw;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
}

.border-line {
  border-color: #c5c5c538;
}

.alert-box {
  padding: 1rem;
  background-color: rgba(115, 103, 240, 0.2);
  border-radius: 6px;
}
</style>
